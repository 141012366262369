<template>
  <list-container-widget
    id="challenges"
    :title="type.key === 0 ? widgetName: translate(type.namePlural) "
    :type="type.key === 0 ? null : `${type.key}`"
    :app-id="24"
    stretch
    class="d-flex"
  >
    <template #actions>
      <!-- Description addon -->
      <b-button
        v-if="
          widget.tagline &&
          translateTranslationTable(
            $store.state.locale.currentLocale,
            widget.tagline
          ) !== ''
        "
        variant="link"
        class="mb-50 p-0 ml-50 link-button"
        @click="isAppInfoModalVisible = true"
      >
        <feather-icon icon="InfoIcon" size="16" />
      </b-button>

      <!-- Panel actions -->
       
       <!-- View All-->
      <ViewAllButton item-type="challenges" />

      <!-- Create New-->
      <CreateNewButton item-type="challenges" />
    </template>

    <!-- Loading -->
    <b-card-body>
      <div v-if="isLoading">
        <b-spinner type="grow" small class="mx-auto mt-3 d-block" />
      </div>

      <!-- Container with data -->
      <div v-else-if="items && items.length > 0 && canList">
        <div
          ref="container"
          class="x-scroll-container w-100"
          @scroll="onScroll"
        >
          <!-- Items Loop -->
          <div
          v-for="(item, index) in items"
          :key="index"
          class="x-scroll-element mx-1 mb-3 p-0"
          >
            <project-card 
              v-if="item.isPublished || (!item.isPublished && isStaff)" 
              :item="item" 
              :itemType="'challenges'" 
              :itemTypeSingle="'challenge'" 
            />
          </div>
          <!-- Buttons prev and next -->
          <button
            class="link-previous btn btn-icon btn-primary btn-previous"
            @click="scrollOnePage(-1)"
          >
            <b-spinner small class="spinner" />
          </button>
          <button
            class="link-next btn btn-icon btn-primary btn-next"
            :class="{ 'btn-next--loading': isLoadingNextPage }"
            @click="scrollOnePage(+1)"
          >
            <b-spinner small class="spinner" />
          </button>
        </div>
      </div>
      <!-- If the loggedUser does not have permissions -->
      <b-row
        v-else-if="items && items.length > 0 && !canList"
        class="horizontal-placeholder"
      >
        <b-col cols="12">
          <img :src="challengePlaceholder" />
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t("no-permisions.message") }}
          </p>
        </b-col>
      </b-row>

      <!-- Container without data: Placeholder -->
      <b-row v-else class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="challengePlaceholder" />
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t("available.message", { itemName: widgetName }) }}
          </p>
        </b-col>
      </b-row>
    </b-card-body>

    <description-addon v-model="isAppInfoModalVisible" :app-id="29" />
  </list-container-widget>
</template>

<script>
import { getImageResource } from "@/@core/utils/image-utils";
import DescriptionAddon from "@/@core/widgets/DescriptionAddon.vue";
import ChallengePlaceholder from "@/assets/images/placeholders/light/challenges.svg";
// import WidgetActions from "@/@core/components/widget-actions/WidgetActions.vue";
// import FileTypes from "@/@core/constants/FileTypes";
// import MembershipEventCreateModal from "@/views/apps/membershipEvents/components/MembershipEventCreateModal.vue";
// import Teleport from "vue2-teleport";
import LanguageSelectorHeader from '@core/components/modal/LanguageSelectorHeader.vue';
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import { ENABLED_APPS_GETTERS } from "@/store/enabled-apps/enabled-apps-store-constants";
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
import { checkPermissions } from "@/@core/utils/roles-utils";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import ProjectCard from '@/views/apps/projects/components/ProjectCard.vue';

export default {
  name: "ChallengesListWidget",
  components: {
    DescriptionAddon,
    ListContainerWidget,
    ProjectCard,
    LanguageSelectorHeader,
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
    CreateNewButton: () => import ("@/@core/components/widget-actions/CreateNewButton.vue"  /* webpackChunkName: "CreateNewButton" */),
  },
    props: {
    type: {
      type: Object,
      required: true,
    },
  },
  mixins: [WidgetLayoutMixin],
  data() {
    return {
      isLoading: true,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      memberForMeeting: {},
      isRequestMeetingVisible: false,
      challengeInput: {},
      imageChallenge: null,
      imageSrc: null,
      isAppInfoModalVisible: false,
      searchInput: "",
      results: [],
      typeOptions: [],
      isModalVisible: false,
      selectedLanguage: this.currentLocale,
      timer: false,
      isLoadingTypes:false
    };
  },
  computed: {
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    finalOptions() {
      return [].concat(
        this.sortedCommunityClassifiers[1],
        this.customFieldsOptions
      );
    },
    // Final filtros computed
    // Load data from store
    items() {
      return this.itemsData?.unpaginated || [];
    },
    itemsData() {
      if (this.type.key !== 0) {
        return this.$store.getters.communitiesOthers[this.type.key];
      }

      return this.$store.getters.communitiesOthers.challenges;
    },
    challengeInfo() {
      return this.$store.getters.info("challenge");
    },
    challengePlaceholder() {
      return ChallengePlaceholder;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    appId() {
      return 24;
    },
    apps() {
      return this.$store.getters.apps;
    },
    getApp() {
      if (this.apps) {
        return this.apps.apps[this.appId] || {};
      }
      return {};
    },
    hasTypes() {
      const showTypes = this.getApp?.customization?.displayOptions;
      return showTypes > 1;
    },
    canAdd() {
      return (
        checkPermissions(
          "create",
          "challenge",
          this.loggedMemberRoles,
          this.collective
        ) 
      );
    },
    canList() {
      return checkPermissions(
        "view",
        "challenge",
        this.loggedMemberRoles,
        this.collective
      );
    },
    canView() {
      return checkPermissions(
        "view",
        "challenge",
        this.loggedMemberRoles,
        this.collective
      );
    },
  },
  setup() {
    const refInputEl = ref(null);
    const refPreviewEl = ref(null);
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    };
  },
  watch: {
    imageChallenge(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    await this.fetchData();
    //await this.fetchClassifiers();
    this.isLoading = false;
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    resetInputs() {
      this.challengeInput = {};
      this.imageChallenge = null;
      this.imageSrc = null;
    },
    closeModal() {
      this.$bvModal.hide(`modal-create-challenges-${this.translate(this.type.namePlural)}`);
    },
    // canFilter() {
    //   if (this.classifiers.length > 0) {
    //     this.showfilter = true;
    //   } else {
    //     this.showfilter = false;
    //   }
    // },
    // Filtros methods
    // toggleFilters() {
    //   this.areFiltersVisible = !this.areFiltersVisible;
    //   if (!this.areFiltersVisible) {
    //     this.customFieldToSend = null;
    //     (this.selectedClassifiersForFilter = null),
    //       (this.finalSelectClassifier = []);
    //     (this.selectedCustomFieldsForFilter = null), (this.nextFilter = null);
    //     (this.total = 0),
    //       (this.filtersIndex = 0),
    //       (this.filters = []),
    //       (this.parentClassifiers = []),
    //       (this.customFieldsInputs = []);
    //     this.index = 0;
    //     this.fetchData(true);
    //   }
    // },
    handleChanger() {
      this.fetchData(true);
    },
    // async setSeletedFinalFilter(selected) {
    //   if (selected && selected.type === 2) {
    //     this.customFieldToSend[selected.key] = selected.text;
    //   } else if (selected) {
    //     this.finalSelectClassifier = selected.key;
    //   } else {
    //     this.finalSelectClassifier = [];
    //   }
    //   this.handleChanger();
    // },
    // async fetchClassifiers() {
    //   await this.$store.dispatch("getItems", {
    //     itemType: "backoffice/classifiers",
    //     storedKey: "challenge",
    //     customName: "classifiers",
    //     page: 1,
    //     requestConfig: {
    //       modelType: "challenges",
    //       count: 1000,
    //     },
    //   });
    //   this.canFilter();
    //   this.isLoading = false;
    // },
    // async setSeletedFilter(selected) {
    //   this.CustomFieldToSend = null;
    //   this.finalSelectClassifier = null;
    //   this.selectedCustomFieldsForFilter = null;
    //   this.nextFilter = null;
    //   if (selected.length === 0) {
    //     this.finalSelectClassifier = null;
    //   } else {
    //     let translated = [];
    //     if (selected.type === 1) {
    //       for (const row of this.sortedCommunityClassifiers[0][selected.key]) {
    //         translated.push({
    //           name: translateTranslationTable(
    //             this.$store.state.locale.currentLocale,
    //             row.name
    //           ),
    //           key: row.key,
    //           type: 1,
    //         });
    //       }
    //     } else if (selected.type === 2) {
    //       this.customFieldToSend[selected.key] = "";
    //       translated = {
    //         type: 2,
    //         key: selected.key,
    //       };
    //     }
    //     this.nextFilter = translated;
    //   }
    // },
    // Final filtros methods
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    // Call store action (with dispach) to load data from backend
    getImageSrc(url) {
      return getImageResource(url);
    },
    async fetchData(force = false, searchString = '') {
      this.isLoading = true;
      await this.$store.dispatch("getItems", {
        itemType: "communities/simply",
        customName: "communitiesOthers",
        storedKey: this.type?.key === 0 ? 'challenges':this.type.key,
        page: this.lastLoadedPage,
        perPage: 30,
        forceAPICall: force,
        requestConfig: {
          searchString,
          isChallenge: true,
          orderByDate: -1,
          typeKey: this.type.key !== 0 ? this.type.key : undefined,
          communityParentSlug: this.$store.getters.currentCollective.slug,
          // classifiers: this.finalSelectClassifier,
          // customFields:
          //   this.customFieldToSend != null &&
          //   Object.keys(this.customFieldToSend).length > 0
          //     ? this.customFieldToSend
          //     : null,
        },
      });
      this.isLoading = false;
    },
    async getChallengeTypes() {
      this.isLoadingTypes = true;
      const response = await this.$store.dispatch('getItems', {
        itemType: 'types',
        storedKey: 'challenges',
        forceAPICall: true,
        page: 1,
        perPage: 200,
        requestConfig: {
          modelType: 'challenge',
          getCommunityTypes: 1,
          selectAll: 1,
        },
      });
      if (response) {
        this.typeOptions = [];
        if (response.data.length > 0) {
          for (const typeOption of response.data) {
            Object.values(typeOption.name).length > 0
              ? this.typeOptions.push({
                code: typeOption.id,
                type: this.translate(typeOption.name),
              })
              : '';
          }
        } else {
          this.typeOptions.push({
            code: null,
            type: 'No hi ha cap tipus creat',
          });
        }
      }
      this.isLoadingTypes = false;
    },
    // When the scroll reaches the end, load more data.
    async onScroll(event) {
      const distanceToEnd =
        event.srcElement.scrollWidth -
        event.srcElement.scrollLeft -
        event.srcElement.offsetWidth;
      if (distanceToEnd < 50 && !this.isLoadingNextPage) {
        this.handleLoadOfNewItems();
      }
    },
    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (this.items.length < total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData();
        this.isLoadingNextPage = false;
      } else {
        this.isLoadingNextPage = false;
      }
    },
    // Scroll forward and back (buttons)
    scrollOnePage(direction) {
      const currentScroll = this.$refs.container.scrollLeft;
      const toScroll = this.$refs.container.offsetWidth;
      this.$scrollTo(this.$refs.container, 500, {
        container: this.$refs.container,
        duration: 500,
        offset: currentScroll + direction * toScroll,
        x: true,
        y: false,
      });
    },
    async handleCreateChallenge() {
      if (!this.challengeInput.name) {
        return;
      }
      try {
        await this.$store.dispatch("createItem", {
          item: {
            itemType: "challenges",
            requestConfig: {
              name: this.challengeInput.name,
              description: this.challengeInput.description,
            },
          },
          file: this.imageChallenge,
        });
        this.challengeInput = {};
        this.imageChallenge = null;
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t("error-message.general-error"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    widget() {
      const app = Object.values(
        this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]
      ).filter((item) => {
        if (item.id === this.appId) {
          return item.name;
        }
      });
      return app[0];
    },
     async toggleLike(item) {
      console.log("tooooggle");
      await this.$store.dispatch("toggleLike", {
        itemType: "communitiesOthers",
        morphType: "challenges",
        key: item.key,
      });
    },
    timestampToTime(timestamp) {
      return moment(timestamp).format("DD-MM-YYYY");
    },
    async searchItems(value) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
    }
    this.timer = setTimeout(async () => {
      await this.fetchData(true, value)
    },800);
    },
    statusColor(item) {
      switch (item.status) {
        case "New":
          return "success";
        case "Discarded":
          return "danger";
        case "Finished":
          return "secondary";
        case "Pending":
          return "warning";
        default:
          return "info";
      }
    },
  },
};
</script>
<style>
.card-challenges {
  height: 424px;
}
.icon-div {
  position: absolute;
  bottom: 0;
  background-color: white;
  margin: auto;
  padding: 2px;
  padding-bottom: 6px;
}
.content-container img {
  max-width: 100%;
}
</style>
